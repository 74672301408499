import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from 'common-legacy';
import { BxpEvent } from '../../../data/BxpEvent';

@Component({
    selector: 'app-bxp-recap',
    imports: [TranslateModule, CommonModule],
    templateUrl: './bxp-recap.component.html',
    styleUrl: './bxp-recap.component.scss'
})
export class BxpRecapComponent {
  
  @Input({required: true})
  store: Store;

  @Input({required: true})
  event: BxpEvent;

  constructor(
    public translateService: TranslateService
  ) {}

  getTimeSlotString(event: BxpEvent) : string {
    const lang = this.translateService.currentLang;
    const option: Intl.DateTimeFormatOptions = {
      hour:'2-digit',
      minute: '2-digit'
    }
    const dateEnd = new Date(event.date);
    dateEnd.setMinutes(event.date.getMinutes() + event.durationMinutes);
    
    return `${event.date.toLocaleTimeString(lang, option)} - ${dateEnd.toLocaleTimeString(lang, option)}`
  }

}
