import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApiConfig, AvailableBookingSlot, AvailableSlotService, McdoBookingFormComponent, Store, StoreService, toLocaleDateTimeString } from 'common-legacy';
import { cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';
import { BxpEvent } from '../../data/BxpEvent';
import { BxpRecapComponent } from '../common/bxp-recap/bxp-recap.component';
import { BxpBookingComponent } from "./bxp-booking/bxp-booking.component";
import { BxpCodeInputComponent } from './bxp-code-input/bxp-code-input.component';

@Component({
    selector: 'app-booking-form',
    imports: [
        McdoBookingFormComponent, BxpBookingComponent, CommonModule, BxpBookingComponent, BxpRecapComponent,
        BxpCodeInputComponent
    ],
    templateUrl: './booking-form.component.html',
    styleUrl: './booking-form.component.scss'
})
export class BookingFormComponent implements OnInit {

  onBxpEventValidate = new Subject<boolean>();

  bxpEvent = new BxpEvent();
  stores: Store[];

  currentSlot: AvailableBookingSlot;
  currentStore: Store;
  nextAvailableDates: Date[];
  eventComplete: boolean;
  bxpCode: string;
  isAuthenticated: boolean;
isSubmitted: any;

  constructor(
    private storeSvc: StoreService,
    private availableSlotSvc: AvailableSlotService<AvailableBookingSlot>,
    private oidcSvc: OidcSecurityService,
    private httpClient: HttpClient,
    private apiConfig: ApiConfig
  ) { }

  ngOnInit(): void {
    this.storeSvc.getAll().subscribe(stores => this.stores = stores);
    this.onBxpEventValidate.subscribe(x => this.eventComplete = x);
    this.oidcSvc.isAuthenticated().subscribe(x => this.isAuthenticated = x);
  }

  onSlotSelected(slot: AvailableBookingSlot) {
    this.currentSlot = slot;
    if (slot) {
      this.bxpEvent.date = slot.timeSlotsStart[0];
      this.bxpEvent.durationMinutes = slot.durationMinutes;
    }
  }

  onStoreSelected(store: Store) {
    this.currentStore = store;
    if (store) {
      this.bxpEvent.storeIdentifier = store.storeIdNumber;

      const dateBegin = new Date();
      const dateEnd = new Date(dateBegin);
      dateEnd.setMonth(dateBegin.getMonth() + 3);

      this.availableSlotSvc.findAvailableBookingBetween(store.storeIdNumber, dateBegin, dateEnd)
      .subscribe(nextAvailableSlots => {
        if (nextAvailableSlots && nextAvailableSlots.timeSlotsStart) {
          const days: Date[] = [];
          for (const day of nextAvailableSlots.timeSlotsStart) {

            const dayIndex = days.findIndex(x => 
              x.getFullYear() === day.getFullYear()
              && x.getMonth() === day.getMonth()
              && x.getDay() === day.getDay()
            );

            if (dayIndex === -1) {
              days.push(day);
            }
          }
          
          this.nextAvailableDates = days.sort();
        } else {
          this.nextAvailableDates = null;
        }
      });
    }
  }
  
  onBookingConfirmed() {
    this.oidcSvc.getUserData().subscribe(userData => {
      if (userData)
        this.bxpEvent.role = userData.roles[0];

      const event = cloneDeep(this.bxpEvent) as any;
      event.date = toLocaleDateTimeString(this.bxpEvent.date);
      
      this.httpClient.post(this.apiConfig.bookingService + "/bookings", {
        code: this.bxpCode,
        party: event
      }).subscribe(() => this.isSubmitted = true);
    });
  }
}
